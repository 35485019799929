import React from 'react';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';

import Link from '@dapclient/components/core/Link';

export interface IFooterMenuItemProps extends React.HTMLProps<HTMLLIElement> {
  icon?: React.ReactNode;
  label: string;
  dst: string;
  className?: string;
  onClick?: (event: any) => void;
}

const StyledList = styled('li')(({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '0.8rem',
  '& > button, a': {
    color: theme.palette.common.white
  },
}));

class FooterMenuItem extends React.Component<IFooterMenuItemProps> {
  render() {
    const { label, dst, icon, onClick } = this.props;
    return (
      <StyledList onClick={onClick}>
        <Link to={dst}>
          {icon && <>{icon} </>} {/*wrapper ensures space preserved*/}
          {label}
        </Link>
      </StyledList>
    );
  }
}
export default FooterMenuItem;
