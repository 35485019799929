import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { faCheck, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import ContactUsService from '@extensions/services/ContactUsService';

import AddIcon from '@extensions/utils/AddIcon';
import FooterMenuItem, { IFooterMenuItemProps } from './FooterMenuItem';

export interface IFooterMenuProps {
  contactUsService?: ContactUsService;
}

const StyledList = styled('ul')(({theme}) => (({
  display: 'flex',
  flexWrap: 'wrap',
  listStyleType: 'none',
  alignItems: 'center',
  marginBlockStart: theme.spacing(1.25),
})));

const StyledSpan = styled('span')(({ theme }) => ({
  fontSize: '0.8rem',
  color: theme.palette.common.white,
  padding: '0 0.25rem',
}));

@inject('contactUsService')
@observer
class FooterMenu extends React.Component<
  IFooterMenuProps
> {
  render() {
    const ITEMS: IFooterMenuItemProps[] = [
      {
        icon: <AddIcon icon={faEnvelope} aria-hidden />,
        label: 'Contact Us',
        dst: '',
        onClick: (event) => {
          event.preventDefault();
          event.stopPropagation();
          if (this.props.contactUsService) {
            this.props.contactUsService.openModal();
          }
        },
      },
      {
        icon: <AddIcon icon={faCheck} aria-hidden />,
        label: 'Acknowledge A2e',
        dst: '/acknowledge',
      },
      {
        label: 'Wind Energy Technologies Office',
        dst: 'http://energy.gov/eere/wind/wind-program',
      },
      {
        label: 'Office of Energy Efficiency & Renewable Energy',
        dst: 'http://energy.gov/eere',
      },
      {
        label: 'FAQ',
        dst: '/faq',
      },
    ];

    return (
      <div>
        <StyledList>
          {ITEMS.map((item, index) => (
            <Fragment key={item.dst}>
              <FooterMenuItem icon={item.icon} label={item.label} dst={item.dst} onClick={item.onClick} />
              {index !== ITEMS.length - 1 && (
                <StyledSpan aria-hidden>
                  |
                </StyledSpan>
              )}
            </Fragment>
          ))}
        </StyledList>
      </div>
    );
  }
}

export default (FooterMenu);
